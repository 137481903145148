import React from 'react';
import Card from './Card';
import StarRanking from '../../../images/events/icons/StarRanking.svg';
import { EventStatus, getEventStatus } from '@src/constants/events/index';

export default function RegisteredCard({ date, durationInMinutes }) {
  const eventStatus = getEventStatus(date, durationInMinutes);
  const isFutureEvent = eventStatus === EventStatus.FUTURE;

  return (
    <Card className={'flex flex-col gap-3 p-7'}>
      <StarRanking />
      <div className="text-xl font-bold">Thank You for Registration</div>
      <div>
        {isFutureEvent
          ? 'Your seat is reserved for this workshop. Please check email for further details'
          : 'We have sent you the recording of the session over email.'}
      </div>
    </Card>
  );
}
