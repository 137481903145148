import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IconLink = ({ className, title, link, icon }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className={`flex cursor-pointer items-center gap-2 ${className}`}
    >
      {icon}

      <h2 className="w-max font-manrope text-base font-bold text-black transition-all duration-300 hover:text-[#009378]">
        {title}
        <FontAwesomeIcon
          className="ml-1 text-sm font-bold"
          icon={['fas', 'angle-right']}
        ></FontAwesomeIcon>
      </h2>
    </a>
  );
};

export default IconLink;
