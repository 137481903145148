import React from 'react';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import EventsNavbar from '@components/Events/EventsNavbar/index';
import EventsBody from '@components/Events/EventsBody/index';
import FooterV2 from '@components/FooterV2/index';

let str = '';
if (typeof window !== 'undefined') {
  let parts = window.location.hostname.split('.');
  let subdomain = parts.shift();
  let upperleveldomain = parts.join('.');

  // eslint-disable-next-line global-require
}

export default function CrioEvents(props) {
  const { pageData } = props.pageContext || {};

  const { seoTitle, seoDescription } = pageData.attributes || {};

  const eventData = pageData.attributes;

  // const pageData = props.pageContext?.data;

  return (
    <>
      <GatsbySeo title={seoTitle} description={seoDescription} noindex={true} />
      <EventsNavbar />
      <div className="h-full bg-[#D6E2DF] p-2 pb-8 md:p-4">
        <EventsBody eventData={eventData} />
      </div>
      <FooterV2 />
    </>
  );
}
