import { Typography, IconButton } from '@mui/material';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EventFormBody from './EventFormBody';

const EventForm = ({ closeDialog = () => {} }) => {
  return (
    <div className="flex max-h-[85vh] w-full flex-col items-stretch bg-v5-green-700 p-2 md:w-[580px] md:px-16 md:pb-6 md:pt-4">
      <div className="relative flex h-min flex-col items-stretch gap-1.5 overflow-y-auto scrollbar-hide">
        <IconButton
          onClick={closeDialog}
          aria-label="exit"
          size="small"
          className="right-0 ml-auto cursor-pointer text-v5-neutral-200 smallheight:absolute"
        >
          <FontAwesomeIcon icon="fa-solid fa-xmark" />
        </IconButton>

        <Typography
          className={`mb-4 text-center text-2xl font-bold text-white`}
        >
          Register
        </Typography>
        <EventFormBody closeDialog={closeDialog} />
      </div>
    </div>
  );
};

export default EventForm;
