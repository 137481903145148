import React from 'react';

export const CalendarIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.71429 2.625C7.71429 2.28125 7.39286 2 7 2C6.60714 2 6.28571 2.28125 6.28571 2.625V4.5H4.85714C3.28125 4.5 2 5.62109 2 7V8.25V9.5V19.5C2 20.8789 3.28125 22 4.85714 22H19.1429C20.7188 22 22 20.8789 22 19.5V9.5V8.25V7C22 5.62109 20.7188 4.5 19.1429 4.5H17.7143V2.625C17.7143 2.28125 17.3929 2 17 2C16.6071 2 16.2857 2.28125 16.2857 2.625V4.5H7.71429V2.625ZM3.42857 9.5H20.5714V19.5C20.5714 20.1914 19.933 20.75 19.1429 20.75H4.85714C4.06696 20.75 3.42857 20.1914 3.42857 19.5V9.5ZM4.85714 5.75H19.1429C19.933 5.75 20.5714 6.30859 20.5714 7V8.25H3.42857V7C3.42857 6.30859 4.06696 5.75 4.85714 5.75ZM6.64286 12H17.3571C17.5536 12 17.7143 12.1406 17.7143 12.3125V15.4375C17.7143 15.6094 17.5536 15.75 17.3571 15.75H6.64286C6.44643 15.75 6.28571 15.6094 6.28571 15.4375V12.3125C6.28571 12.1406 6.44643 12 6.64286 12ZM4.85714 12.3125V15.4375C4.85714 16.3008 5.65625 17 6.64286 17H17.3571C18.3438 17 19.1429 16.3008 19.1429 15.4375V12.3125C19.1429 11.4492 18.3438 10.75 17.3571 10.75H6.64286C5.65625 10.75 4.85714 11.4492 4.85714 12.3125Z"
        fill="#7A8B87"
      />
    </svg>
  );
};
