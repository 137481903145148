import React from 'react';
import Card from './Card';
import DateCard from '../DateCard/index';
import IconWrapper from '../IconWrapper/index';
import LabelCard from './LabelCard';
import IconLink from './IconLink';
import { BeaverLogo } from '@src/images/events/icons/BeaverLogo';
import { GlobeIcon } from '@src/images/events/icons/GlobeIcon';
import { add, format } from 'date-fns';
import { GatsbyImage } from 'gatsby-plugin-image';

const Hero = ({ title, image, date, durationInMinutes }) => {
  return (
    <div className="flex-1">
      <Card className="p-2">
        <GatsbyImage
          loading="lazy"
          image={image}
          alt={'profile'}
          style={{ maxHeight: '100%' }}
          className="rounded-[10px]"
          imgStyle={{ objectFit: 'contain' }}
        />
        <div className="grid grid-cols-6 justify-items-start gap-y-2 p-2 md:gap-y-4 md:p-3">
          <h1 className="col-span-6 font-rubik text-[24px] font-semibold leading-tight md:col-span-5 md:text-4xl">
            {title}
          </h1>
          <div className="flex hidden justify-self-end md:block">
            <DateCard date={date} className="h-20 w-20" />
          </div>

          <IconLink
            title={'Crio.do'}
            icon={
              <div className="flex h-5 w-5 items-center justify-center rounded-[3px] bg-[#2DF8C5]">
                <BeaverLogo />
              </div>
            }
            className="col-span-6"
            link={'https://crio.do'}
          />

          <div className="col-span-full md:col-span-3">
            <LabelCard
              icon={<DateCard date={date} className="h-11 w-11" variant="sm" />}
              title={format(new Date(date), 'EEEE, d LLLL')}
              content={`${format(new Date(date), 'hh:mm')} to ${format(
                add(new Date(date), { minutes: durationInMinutes }),
                'hh:mm aaa',
              )} IST`}
            />
          </div>

          <div className="col-span-3 hidden md:block">
            <LabelCard
              icon={
                <IconWrapper className="h-11 w-11">
                  <GlobeIcon />
                </IconWrapper>
              }
              title={'Virtual'}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Hero;
