export const EventStatus = {
  PAST: 'Past',
  PRESENT: 'Present',
  FUTURE: 'Future',
};

export const getEventStatus = (givenDateStr, durationMinutes) => {
  const givenDate = new Date(givenDateStr);
  const endDate = new Date(givenDate.getTime() + durationMinutes * 60000);
  const currentDate = new Date();

  if (endDate < currentDate) {
    return EventStatus.PAST;
  } else if (givenDate <= currentDate && currentDate <= endDate) {
    return EventStatus.PRESENT;
  } else {
    return EventStatus.FUTURE;
  }
};

export const getTimeElapsedString = (givenDateStr, durationMinutes) => {
  const givenDate = new Date(givenDateStr);
  const currentDate = new Date();
  const timeDifferenceInMilliseconds = currentDate - givenDate;
  const timeDifferenceInDays = Math.floor(
    timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24),
  );

  if (timeDifferenceInDays < 10) {
    return `${timeDifferenceInDays} days ago`;
  } else {
    const timeDifferenceInWeeks = Math.floor(timeDifferenceInDays / 7);
    return `${timeDifferenceInWeeks} weeks ago`;
  }
};
