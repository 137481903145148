import React from 'react';

export const GlobeIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1815_6)">
        <path
          d="M12 20.75C12.6523 20.75 13.5781 20.1875 14.418 18.5117C14.8047 17.7383 15.1289 16.8047 15.3594 15.75H8.64062C8.87109 16.8047 9.19531 17.7383 9.58203 18.5117C10.4219 20.1875 11.3477 20.75 12 20.75ZM8.41797 14.5H15.582C15.6914 13.7109 15.75 12.8711 15.75 12C15.75 11.1289 15.6914 10.2891 15.582 9.5H8.41797C8.30859 10.2891 8.25 11.1289 8.25 12C8.25 12.8711 8.30859 13.7109 8.41797 14.5ZM8.64062 8.25H15.3594C15.1289 7.19531 14.8047 6.26172 14.418 5.48828C13.5781 3.8125 12.6523 3.25 12 3.25C11.3477 3.25 10.4219 3.8125 9.58203 5.48828C9.19531 6.26172 8.87109 7.19531 8.64062 8.25ZM16.8438 9.5C16.9453 10.3008 17 11.1367 17 12C17 12.8633 16.9453 13.6992 16.8438 14.5H20.3906C20.625 13.707 20.7539 12.8672 20.7539 12C20.7539 11.1328 20.6289 10.293 20.3906 9.5H16.8438ZM19.9102 8.25C18.8984 6.12109 17.0547 4.46484 14.7969 3.70703C15.6172 4.8125 16.2656 6.39453 16.6406 8.25H19.9141H19.9102ZM7.36719 8.25C7.74219 6.39062 8.39062 4.8125 9.21094 3.70703C6.94922 4.46484 5.10156 6.12109 4.09375 8.25H7.36328H7.36719ZM3.61719 9.5C3.38281 10.293 3.25391 11.1328 3.25391 12C3.25391 12.8672 3.37891 13.707 3.61719 14.5H7.15625C7.05469 13.6992 7 12.8633 7 12C7 11.1367 7.05469 10.3008 7.15625 9.5H3.61328H3.61719ZM14.793 20.293C17.0508 19.5312 18.8945 17.8789 19.9062 15.75H16.6367C16.2617 17.6094 15.6133 19.1875 14.793 20.293ZM9.20703 20.293C8.38672 19.1875 7.74219 17.6055 7.36328 15.75H4.09375C5.10547 17.8789 6.94922 19.5352 9.20703 20.293ZM12 22C9.34784 22 6.8043 20.9464 4.92893 19.0711C3.05357 17.1957 2 14.6522 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12C22 14.6522 20.9464 17.1957 19.0711 19.0711C17.1957 20.9464 14.6522 22 12 22Z"
          fill="#7A8B87"
        />
      </g>
      <defs>
        <clipPath id="clip0_1815_6">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
