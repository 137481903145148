import RawHTML from '@components/Common/RawHTML/index';
import { PageIcon } from '@src/images/events/icons/PageIcon';
import React from 'react';
import HeadingCard from './HeadingCard';
import * as styles from './About.module.scss';

const AboutCard = ({ about }) => {
  return (
    <div className="border">
      <HeadingCard heading={'About Event'} icon={<PageIcon />}>
        {/* <div
        className="flex flex-col gap-5 font-manrope font-semibold"
        dangerouslySetInnerHTML={{ __html: about }}
      ></div> */}
        <RawHTML
          className={`${styles.about} flex flex-col gap-5 font-manrope font-semibold`}
        >
          {about}
        </RawHTML>
      </HeadingCard>
    </div>
  );
};

export default AboutCard;
