import React from 'react';
import { CrioLogo } from '@src/images/events/icons/CrioLogo';
import SignInButton from '@components/SignInButton/index';

const EventsNavbar = () => {
  return (
    <div className="flex h-[54px] w-full items-center justify-between bg-v5-green-700 px-2 text-white md:h-[80px] md:px-6">
      <div>
        <CrioLogo className="h-[30px] w-[120px] md:h-auto md:w-auto" />
      </div>
      <div>
        <SignInButton className="border-white px-3 text-white md:px-6" />
      </div>
    </div>
  );
};

export default EventsNavbar;
