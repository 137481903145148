import React from 'react';
import IconWrapper from '../IconWrapper/index';
import Card from './Card';

const HeadingCard = ({ icon, className, heading, children }) => {
  return (
    <Card className="">
      <div className={`border-b py-4 px-5 ${className}`}>
        <div className="flex h-full items-center gap-2">
          <IconWrapper className="h-8 w-8 border-0">{icon}</IconWrapper>
          <div className="font-manrope">
            <h3 className="text-lg font-bold">{heading}</h3>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-3 px-5 py-4">{children}</div>
    </Card>
  );
};

export default HeadingCard;
