import React from 'react';
import IconWrapper from '../IconWrapper/index';
import LabelCard from './LabelCard';
import HeadingCard from './HeadingCard';
import { RegistrationIcon } from '@src/images/events/icons/Registration';
import { CalendarIcon } from '@src/images/events/icons/CalendarIcon';
import EventFormButton from '../EventsForm/index';
import {
  EventStatus,
  getEventStatus,
  getTimeElapsedString,
} from '@src/constants/events/index';

const RegistrationCard = ({ date, durationInMinutes }) => {
  const eventStatus = getEventStatus(date, durationInMinutes);
  const isPastEvent = eventStatus === EventStatus.PAST;

  return (
    <div className="border">
      <HeadingCard
        heading={'Registration'}
        className=""
        icon={<RegistrationIcon />}
      >
        {isPastEvent && (
          <div className="-mx-4 -mt-3 bg-[#009378] bg-opacity-10 py-2 px-3">
            <LabelCard
              icon={
                <IconWrapper>
                  <CalendarIcon />
                </IconWrapper>
              }
              title={'Past Event'}
              titleClassName="text-base"
              content={`This event ended ${getTimeElapsedString(
                date,
                durationInMinutes,
              )}.`}
              contentClassName="text-sm"
            />
          </div>
        )}
        <div>
          <p>Hello! To join the event, please register below.</p>
        </div>
        <div>
          <EventFormButton />
        </div>
      </HeadingCard>
    </div>
  );
};

export default RegistrationCard;
