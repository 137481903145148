import React from 'react';

const LabelCard = ({
  icon,
  title,
  content,
  titleClassName,
  contentClassName,
  className,
}) => {
  return (
    <div className={`flex h-full items-center items-center gap-4 ${className}`}>
      {icon}
      <div className="font-manrope">
        <h4 className={`text-lg font-bold ${titleClassName}`}>{title}</h4>
        {content && (
          <p
            className={`text-base font-bold text-[#525252] ${contentClassName}`}
          >
            {content}
          </p>
        )}
      </div>
    </div>
  );
};

export default LabelCard;
