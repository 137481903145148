import React from 'react';

const IconWrapper = ({ className, children }) => {
  return (
    <div
      className={`inline-flex h-10 w-10 flex-col items-center justify-center rounded-[10px] border-[1px] border-[#B9CFCA] font-bold ${className}`}
    >
      {children}
    </div>
  );
};

export default IconWrapper;
