import React from 'react';

export const PageIcon = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 18.75H3C2.30859 18.75 1.75 18.1914 1.75 17.5V2.5C1.75 1.80859 2.30859 1.25 3 1.25H8V5.625C8 6.66016 8.83984 7.5 9.875 7.5H14.25V17.5C14.25 18.1914 13.6914 18.75 13 18.75ZM9.875 6.25C9.53125 6.25 9.25 5.96875 9.25 5.625V1.26953C9.35937 1.29687 9.46094 1.35156 9.53906 1.43359L14.0664 5.96094C14.1484 6.04297 14.2031 6.14063 14.2305 6.25H9.875ZM3 0C1.62109 0 0.5 1.12109 0.5 2.5V17.5C0.5 18.8789 1.62109 20 3 20H13C14.3789 20 15.5 18.8789 15.5 17.5V6.40234C15.5 5.90625 15.3008 5.42969 14.9492 5.07812L10.4258 0.550781C10.0742 0.199219 9.59766 0 9.10156 0H3ZM4.875 10C4.53125 10 4.25 10.2812 4.25 10.625C4.25 10.9688 4.53125 11.25 4.875 11.25H11.125C11.4688 11.25 11.75 10.9688 11.75 10.625C11.75 10.2812 11.4688 10 11.125 10H4.875ZM4.875 12.5C4.53125 12.5 4.25 12.7812 4.25 13.125C4.25 13.4688 4.53125 13.75 4.875 13.75H11.125C11.4688 13.75 11.75 13.4688 11.75 13.125C11.75 12.7812 11.4688 12.5 11.125 12.5H4.875ZM4.875 15C4.53125 15 4.25 15.2812 4.25 15.625C4.25 15.9688 4.53125 16.25 4.875 16.25H11.125C11.4688 16.25 11.75 15.9688 11.75 15.625C11.75 15.2812 11.4688 15 11.125 15H4.875Z"
        fill="black"
      />
    </svg>
  );
};
