import Button from '@components/Button/index';
import { twMerge } from 'tailwind-merge';
import React from 'react';
import { useDialog } from '@components/v5/Dialog/index';
import EventForm from './EventForm';
import useResizer from '@components/extra/useResizer';
import EventFormMobile from './EventFormMobile';

const EventFormButton = ({ children, className }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  // callback when dialog is closed from mobile view
  const handleMobileDialogClose = () => {
    document.documentElement.style.removeProperty('overflow-y');
    setDialogOpen(false);
  };

  const [openDialog, closeDialog] = useDialog();
  const isMobile = useResizer();

  return (
    <>
      <Button
        onClick={() => {
          if (!isMobile) {
            openDialog({ children: <EventForm closeDialog={closeDialog} /> });
          } else {
            setDialogOpen(true);
          }
        }}
        className={twMerge(
          `natural sm flex h-[44px] w-full items-center whitespace-nowrap rounded-[10px] border-2 border-v5-yellow-200 bg-v5-yellow-200 py-4 px-8 font-manrope text-base font-bold capitalize text-black shadow-lg shadow-v5-yellow-200/50 hover:shadow-xl  hover:shadow-v5-yellow-100/50 ${
            className ? className : ''
          }`,
        )}
        type="contained"
      >
        Register
      </Button>
      <EventFormMobile
        open={dialogOpen}
        closeDialog={handleMobileDialogClose}
      />
    </>
  );
};

export default EventFormButton;
