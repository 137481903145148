import React from 'react';

export const RegistrationIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 7.22222C2 5.99653 2.99653 5 4.22222 5H19.7778C21.0035 5 22 5.99653 22 7.22222V9.51389C22 9.76736 21.8299 9.98958 21.5833 10.0521C20.8646 10.2361 20.3333 10.8889 20.3333 11.6667C20.3333 12.4444 20.8646 13.0972 21.5833 13.2812C21.8299 13.3437 22 13.566 22 13.8194V16.1111C22 17.3368 21.0035 18.3333 19.7778 18.3333H4.22222C2.99653 18.3333 2 17.3368 2 16.1111V13.8194C2 13.566 2.17014 13.3437 2.41667 13.2812C3.13542 13.0972 3.66667 12.4444 3.66667 11.6667C3.66667 10.8889 3.13542 10.2361 2.41667 10.0521C2.17014 9.98958 2 9.76736 2 9.51389V7.22222ZM4.22222 6.11111C3.60764 6.11111 3.11111 6.60764 3.11111 7.22222V9.12153C4.09028 9.54861 4.77778 10.5278 4.77778 11.6667C4.77778 12.8056 4.09028 13.7847 3.11111 14.2118V16.1111C3.11111 16.7257 3.60764 17.2222 4.22222 17.2222H19.7778C20.3924 17.2222 20.8889 16.7257 20.8889 16.1111V14.2118C19.9097 13.7847 19.2222 12.8056 19.2222 11.6667C19.2222 10.5278 19.9097 9.54861 20.8889 9.12153V7.22222C20.8889 6.60764 20.3924 6.11111 19.7778 6.11111H4.22222Z"
        fill="black"
      />
    </svg>
  );
};
