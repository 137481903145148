import React from 'react';
import IconWrapper from '../IconWrapper/index';
import Card from './Card';
import IconLink from './IconLink';
import LabelCard from './LabelCard';
import { BeaverLogo } from '@src/images/events/icons/BeaverLogo';

const PresenterCard = () => {
  return (
    <div className="md:w-[380px]">
      <Card>
        <LabelCard
          icon={
            <IconWrapper className="h-9 w-9 rounded-[4px] border-0 bg-[#2DF8C5]">
              <BeaverLogo className="h-7 w-7" />
            </IconWrapper>
          }
          title={'Presented by'}
          titleClassName={'text-sm text-[#7A8B87]'}
          className="px-5 pt-4"
          content={
            <IconLink title={'Crio.do'} link={'https://crio.do'}></IconLink>
          }
        />
        <div className="flex max-h-[300px] flex-col gap-3 px-5 py-4">
          <div className="no-scrollbar overflow-y-scroll md:max-h-[200px]">
            <p>
              Crio.Do is the world’s first experiential learning platform that
              focuses on a hands-on, work experience-based approach. So, instead
              of just watching tutorials, learners actually experience building
              real products like professional engineers. The #LearnByDoing
              approach has helped 1000+ passionate tech professionals fulfill
              their career dreams and get into top-tech companies.
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default PresenterCard;
