import React, { useContext } from 'react';
import { useAuth } from '@src/firebase/useAuth';
import { doLoginToBackend } from '../../api/index';
import toast from 'react-hot-toast';
import '@szhsin/react-menu/dist/index.css';
import CrioButton from '@components/v5/CrioButton/index';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import { GTM } from '../../analytics/gtm';
import { gtmEvents } from '../../analytics/gtmEventsConstant';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';

const SignInButton = ({ className }) => {
  const state = useContext(GlobalStateContext);

  const auth = useAuth();

  const handlePostFirebaseLogin = async (idToken, cb) => {
    let response = await doLoginToBackend(idToken);

    if (response) {
      cb();
    } else {
      toast.error(
        'Authentication failed due to poor network connection. Please refresh your browser and try again.',
        { duration: 8000, style: { fontSize: '14px' }, id: 'auth' },
      );
    }
  };

  async function handleLogin(e) {
    GTM.track(gtmEvents.USER_SIGNIN);
    GTM.leadSquaredEvent(ELeadSquaredActivityCode.SIGN_IN_CLICKED, state.email);
    e.preventDefault();
    if (auth?.user) {
      const idToken = await auth.getIdToken();

      // track signup in web-engage
      GTM.track(gtmEvents.USER_SIGNEDUP, {
        email: auth?.user?.email,
        displayName: auth?.user?.displayName,
      });

      handlePostFirebaseLogin(idToken, () => {
        window.open('/learn/home/', '_self');
      });
      return;
    }
    auth.signin().then(async (res) => {
      const idToken = res.idToken;

      // track signup in web-engage
      GTM.track(gtmEvents.USER_SIGNEDUP, {
        email: res?.user?.email,
        displayName: res?.user?.displayName,
      });

      handlePostFirebaseLogin(idToken, () => {
        window.open('/learn/home/', '_self');
      });

      return;
    });
  }

  return (
    <div>
      <CrioButton
        variant="secondary"
        className={`h-[38px] ${className}`}
        onClick={handleLogin}
      >
        {'Sign In'}
      </CrioButton>
    </div>
  );
};

export default SignInButton;
