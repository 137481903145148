import React from 'react';
import { Dialog, Slide } from '@mui/material';
import { Typography, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EventFormBody from './EventFormBody';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EventFormMobile = ({ open, closeDialog = () => {} }) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={closeDialog}
      TransitionComponent={Transition}
    >
      <div className="flex h-screen w-full flex-col items-stretch bg-v5-green-700 p-4 py-8">
        <div className="relative flex h-min flex-col items-stretch gap-1.5 overflow-y-auto scrollbar-hide">
          <IconButton
            onClick={closeDialog}
            aria-label="exit"
            size="small"
            className="right-0 ml-auto cursor-pointer text-v5-neutral-200 smallheight:absolute"
          >
            <FontAwesomeIcon icon="fa-solid fa-xmark" />
          </IconButton>

          <Typography
            className={`mb-4 text-center text-2xl font-bold text-white`}
          >
            Register
          </Typography>
          <EventFormBody closeDialog={closeDialog} />
        </div>
      </div>
    </Dialog>
  );
};

export default EventFormMobile;
