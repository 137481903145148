import React, { useContext } from 'react';
import PresenterCard from './PresenterCard';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import Hero from './Hero';
import AboutCard from './AboutCard';
import ContactCard from './ContactCard';
import RegistrationCard from './RegistrationCard';
import { timeToMinutes } from '../../../utils/helper';
import { useLocation } from '@reach/router';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import RegisteredCard from './RegisteredCard';

const EventsBody = ({ eventData }) => {
  const { title, about, banner, date, duration } = eventData || {};

  const location = useLocation();
  const pathName = location?.pathname;
  const globalState = useContext(GlobalStateContext);
  const userEventSlugsAppliedFor = globalState?.userEventSlugsAppliedFor || [];
  const shouldShowRegisteredBanner =
    userEventSlugsAppliedFor.includes(pathName);

  const hash = banner.data?.attributes.hash;

  const contactEmail = 'programs@criodo.com';

  const data = useStaticQuery(
    graphql`
      query {
        allEventBanners: allFile(
          filter: { relativePath: { regex: "images/strapi/crio-events/" } }
        ) {
          nodes {
            name
            childImageSharp {
              gatsbyImageData(
                blurredOptions: {}
                width: 1000
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    `,
  );

  const eventBanner = data?.allEventBanners.nodes.find((node) =>
    node.name.includes(hash),
  );

  const durationInMinutes = timeToMinutes(duration);

  const image = getImage(eventBanner);

  return (
    <div className="mx-auto flex max-w-[950px] flex-col gap-3">
      <Hero
        image={image}
        title={title}
        date={date}
        durationInMinutes={durationInMinutes}
      />
      <div className="flex flex-col gap-4 md:flex-row">
        <div className="flex flex-1 flex-col gap-4">
          {shouldShowRegisteredBanner ? (
            <RegisteredCard date={date} durationInMinutes={durationInMinutes} />
          ) : (
            <RegistrationCard
              date={date}
              durationInMinutes={durationInMinutes}
            />
          )}
          <AboutCard about={about} />
        </div>

        <div className="flex flex-col gap-4">
          <PresenterCard></PresenterCard>
          <ContactCard contactEmail={contactEmail} />
        </div>
      </div>
    </div>
  );
};

export default EventsBody;
