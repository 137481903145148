import { format } from 'date-fns';
import React from 'react';

const DateCard = ({ date, className, variant }) => {
  const month = format(new Date(date), 'MMM');
  const day = format(new Date(date), 'dd');

  if (variant === 'sm') {
    return (
      <div
        className={`inline-flex h-full flex-col items-center rounded-[10px] border-[1px] border-[#B9CFCA] bg-[#B9CFCA] font-bold ${className}`}
      >
        <h2 className="flex h-1/2 w-full items-center justify-center rounded-t-[9px] bg-white text-center text-[10px] uppercase">
          {month}
        </h2>
        <h3 className="flex h-full items-center justify-center text-lg">
          {day}
        </h3>
      </div>
    );
  }

  return (
    <div
      className={`inline-flex flex-col items-center border-[1px] border-[#009378] font-bold md:rounded-[12px] md:bg-[#009378] ${className}`}
    >
      <h2 className="flex h-1/2 w-full items-center justify-center rounded-t-[11px] bg-white text-center text-xs uppercase">
        {month}
      </h2>
      <h3 className="flex h-full items-center justify-center text-3xl text-white">
        {day}
      </h3>
    </div>
  );
};

export default DateCard;
