import { BeaverLogo } from '@src/images/events/icons/BeaverLogo';
import { HostIcon } from '@src/images/events/icons/Host';
import React from 'react';
import HeadingCard from './HeadingCard';
import IconLink from './IconLink';

const ContactCard = ({ contactEmail }) => {
  return (
    <div className="">
      <HeadingCard heading={'Hosts'} icon={<HostIcon />}>
        <div className="w-full">
          <IconLink
            title={'Crio.do'}
            icon={
              <div className="flex h-7 w-7 flex-wrap items-center justify-center rounded-full bg-[#2DF8C5]">
                <BeaverLogo className="h-4 w-4" />
              </div>
            }
            link={'https://crio.do'}
          />
          <a
            className="mt-4 flex h-10 w-full items-center justify-center rounded-[10px] border border-black py-4 px-8 font-manrope text-base font-bold font-bold capitalize text-black md:h-[44px] md:border-2"
            href={`mailto: ${contactEmail}`}
          >
            Contact
          </a>
        </div>
      </HeadingCard>
    </div>
  );
};

export default ContactCard;
